import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/pageLayout/pageLayout';
import OrderList from '../../components/orderList/orderList';
import { Paper, Typography } from '@mui/material';

function SearchResults(props) {
    const [orders, setOrders] = useState([]);
    const { orderNum } = props.match.params;
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/getone?id=${orderNum}&databaseId=tbdispatch`)
            .then(res => {
                console.log(res)
                if(res.data.length > 0) {
                    console.log(res)
                    setOrders(res.data);
                    setNoResults(false);
                }
                else {
                    setNoResults(true)
                }
            })
    }, [])

    return(
        <PageLayout>
            {orders.length > 0 && !noResults &&
                <OrderList orders={orders} />
            }

            {noResults && orders.length === 0 &&
                <Paper textAlign='center' p={3} sx={{width: '90%', maxWidth: '300px', m: '0 auto', mt: 3, textAlign: 'center', p: 3}}>
                    <Typography variant='h6'>
                        Oops!
                    </Typography>
                    We couldn't find any results for order # {orderNum}. Please try a different order number.
                </Paper>
            }  
        </PageLayout>
    )
}

export default SearchResults;