import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ConstructionIcon from '@mui/icons-material/Construction';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SyncIcon from '@mui/icons-material/Sync';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { History, OpenInNew } from '@mui/icons-material';
import br from '../../images/br.png';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const drawerWidth = 240;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function PageLayout(props) {
    const { window, children, page } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const [searchTerm, setSearchTerm] = React.useState('');

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function logout() {
        localStorage.removeItem('user');
        history.push('/');
    }

  const drawer = (
    <div>
        <Toolbar sx={{py: 1}}>
            <img src={br} alt='' style={{display: 'block', margin: '0 auto', width: '75px'}} />
        </Toolbar>
        <Divider />
            <List>
                <ListItem 
                    href='/neworder'
                    component='a' 
                    button 
                    key='New Order'
                    sx={page === 'neworder' ? {color: '#0b56a4'} : {}}>
                    <ListItemIcon>
                        <ConstructionIcon />
                    </ListItemIcon>
                    <ListItemText primary='New Order' />
                </ListItem>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Work Orders" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton 
                            component='a'
                            href='/orders/filter/new'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                {/* <AllInboxIcon /> */}
                            </ListItemIcon>
                            <ListItemText primary="New" />
                        </ListItemButton>
                        <ListItemButton 
                            component='a'
                            href='/orders'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <AllInboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="All" />
                        </ListItemButton>
                       
                        <ListItemButton 
                            component='a'
                            href='/orders/filter/accepted'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <HourglassEmptyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Accepted" />
                        </ListItemButton>
                        <ListItemButton 
                            component='a'
                            href='/orders/filter/dispatched'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <LocalShippingIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dispatched" />
                        </ListItemButton>
                        <ListItemButton 
                            component='a'
                            href='/orders/filter/progress'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <SyncIcon />
                            </ListItemIcon>
                            <ListItemText primary="In Progess" />
                        </ListItemButton>
                        <ListItemButton 
                            component='a'
                            href='/orders/filter/completed'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary="Completed" />
                        </ListItemButton>
                        <ListItemButton 
                            component='a'
                            href='/orders/filter/reopened'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <OpenInNew />
                            </ListItemIcon>
                            <ListItemText primary="Reopened" />
                        </ListItemButton>
                        <ListItemButton 
                            component='a'
                            href='/orders/filter/archived'
                            sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <History/>
                            </ListItemIcon>
                            <ListItemText primary="Archived" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: '#a6192e'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{flexGrow: 1}}  noWrap component="div">
                Dispatch
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Order #..."
              inputProps={{ 'aria-label': 'search' }}
              onKeyDown={(event) => {
                if(event.code === 'Enter') {
                    history.push(`/search/${searchTerm}`)
                }
              }}
            />
          </Search>
          <Button 
            onClick={logout}
            edge='end'
            color="inherit">Logout</Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ 
            flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` },
            bgcolor: 'whitesmoke',
            width: '100%',
            minHeight: '100vh'
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

PageLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PageLayout;