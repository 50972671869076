import { Box, Paper } from '@mui/material';
import React from 'react';
import PageLayout from '../../components/pageLayout/pageLayout';
import OrderForm from './form';


function NewOrder() {
    return(
        <PageLayout
            page='neworder'
        >
            <Paper
                sx={{
                    m: 3
                }}
            >
                <Box p={5}>
                    <OrderForm />
                </Box>
            </Paper>
        </PageLayout>
    )
}

export default NewOrder;