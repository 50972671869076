import React from "react";
import { Paper } from "@mui/material";

function SubComponent(props) {

    const { children } = props;

    return(
        <Paper sx={{width: '95%', maxWidth: '800px', m: '0 auto', bgcolor: 'background.paper', p: 4, mb: 3}}>
            { children }
        </Paper>
    )
}

export default SubComponent;