import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Orders from './pages/orders/orders';
import NewOrder from './pages/newOrder/newOrder';
import OpenOrder from './pages/openOrder/openOrder';
import Login from './pages/login/login';
import SubPortal from './pages/subPortal/subPortal';
import OpenSub from './pages/openSub/openSub';
import SearchResults from './pages/searchResults/searchResults';

import { useClearCache } from 'react-clear-cache';

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if(!isLatestVersion) {
      emptyCacheStorage()
    }
  }, []);

  return (
      <Router>
        <Route exact path='/' component={Login} />
        <Route exact path='/orders' component={Orders} />
        <Route exact path='/orders/all' component={Orders} />
        <Route exact path='/orders/filter/:status' component={Orders} />
        <Route exact path='/neworder' component={NewOrder} />
        <Route exact path='/orders/:id' component={OpenOrder} />
        <Route exact path='/subportal' component={SubPortal} />
        <Route exact path='/subportal/:id' component={OpenSub} />
        <Route exact path='/search/:orderNum' component={SearchResults} />
      </Router>
  );
}

export default App;