import React from 'react';
import { CssBaseline, Box, AppBar, Typography, Button, Toolbar } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function SubLayout(props) {
    const { children } = props;
    const history = useHistory();

    function logout() {
        localStorage.removeItem('sub');
        history.push('/')
    }

    return(
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: '#a6192e'
          }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{flexGrow: 1}}  noWrap component="div">
                  Subcontractor Portal
            </Typography>
            <Button 
              onClick={logout}
              edge='end'
              color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        </Box>
        <Box
          component="main"
          sx={{ 
              flexGrow: 1, width: '100%',
              bgcolor: 'whitesmoke',
              width: '100%',
              minHeight: '100vh'
          }}
        >
            <Toolbar />
            {children}
        </Box>
      </Box>
    )
}

export default SubLayout;