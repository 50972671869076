const order = {
    archived: false,
    urgent: false,
    subComments: [],
    location: '',
    assignedSub: {},
    history: [
        {
            status: 'New',
            time: ''
        }
    ],
    serviceType: '',
    pictures: [],
    contact: {
        name: '',
        phone: '',
        email: ''
    },
    issue: '',
    subUpdates: {
        notes: "",
        pictures: "",
        date: "",
        subComplete: false,
        managerComplete: false,
        managerSig: '',
        manager: '',
        invoice: []
    },
    notes: [],
    privateNotes: [],
    urgency: '4 hours'
}

export default order