import React, { useEffect, useState } from 'react';
import { Typography, Card, Grid, CardContent, Box } from '@mui/material';
import axios from 'axios';
import SubLayout from '../../components/subLayout/subLayout';

function SubPortal() {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const email = localStorage.getItem('sub').toLowerCase();

        // get all orders from database
        axios.get('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/getall?containerId=orders&databaseId=tbdispatch')
            .then(res => {
                // filter orders to those assigned to logged in subcontractor
                // exclude archived orders
                let filtered = res.data.filter(order => order.assignedSub.email === email && !order.archived).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                
                // update state
                setOrders(filtered);
            })
    }, [])

    return(
       <SubLayout>
            <Grid
                container
                spacing={3}
                p={3}
            >
                {orders.length === 0 &&
                    <Box mt={5} sx={{width: '90%', maxWidth: '300px', margin: '0 auto', paddingTop: '40px'}}>
                        <Typography variant='h5' style={{width: '100%', textAlign: 'center'}}>
                            Oops!
                        </Typography>
                        <Typography variant='body1' mt={1} style={{width: '100%', textAlign: 'center'}}>
                            We could not find any orders assigned to you. If you think this is a mistake, please contact our Admin team.
                        </Typography>
                    </Box>
                }
                {orders.length > 0 &&
                    orders.map(order => {
                        return(
                            <Grid xs={12} md={4} item key={order.id} component='a' href={`/subportal/${order.id}`} sx={{textDecoration: 'none'}} alignItems='stretch'>
                                <Grid 
                                    item 
                                    component={Card} 
                                    sx={{ height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                    <CardContent >
                                        <Typography sx={{fontWeight: 'bold', fontSize: '18px', mb: 1}}>
                                            {order.serviceType}
                                        </Typography>
                                        <Typography sx={{mb: 1}} variant='body2'>
                                            {new Date(order.date).toLocaleString()}
                                        </Typography>
                                        <Typography sx={{mb: 1}} variant='body2'>
                                            {order.location}
                                        </Typography>
                                        <Typography sx={{mb: 1}} variant='body2'>
                                            Order #: {order.id}
                                        </Typography>
                                        <Typography variant='body2'>
                                            Status: <b style={{color: '#a6192e'}}>{order.history[order.history.length-1].status}</b>
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
       </SubLayout>
    )
}

export default SubPortal;