const services = [
    'HVAC / Refrigeration',
    'Plumbing',
    'Electrical',
    'Restaurant equipment repairs',
    'Locksmith',
    'Monthly PM inspection',
    'Roofing',
    'Door repair / Window replacement',
    'Hood vent maintenance',
    'Concrete repairs',
    'Fire suppression',
    'Asphalt Services: paving, sealing, striping, concrete, and snow removal',
    'Landscape',
    'Retrofits, remodels, and construction',
    'General maintenance'
]

export default services;