import React from 'react';
import {Box, Typography, TextField, Button, Alert } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import LoginLogic from './loginLogic';

function ForgotPassword(props) {
    const {
        resetPassword,
        loginValues,
        setLoginValues,
        emailSent,
        errors,
    } = LoginLogic();

    const {setDisplayForgotPassword} = props;

    return(
        <Box
            p={4}
            pt={2}
        >
            <Button onClick={() => setDisplayForgotPassword(false)}
                startIcon={<ArrowBack />} 
                sx={{pl: 0, mb: 2}}>
                Back to login
            </Button>
            <Typography variant='body2' mb={2}>
                Enter your email and if your email is found, we will send you a temporary password.
            </Typography>
            <TextField
                error={errors.email.length > 0 ? true : false}
                fullWidth
                variant='standard'
                label='Email'
                sx={{mb: 2}}
                value={loginValues.email}
                onChange={(e) => setLoginValues({...loginValues, email: e.target.value})}
                helperText={errors.email}
                onKeyDown={(event) => {
                    if(event.code === 'Enter') {
                        resetPassword()
                    }
                }}
            />

            {emailSent &&
                <Alert severity='success' sx={{mb: 2}}>
                    Temporary password has been sent to your email!
                </Alert>
            }

            <Button 
                fullWidth
                variant='contained'
                onClick={resetPassword}
                sx={{mt: 2}}
            >
                SEND EMAIL
            </Button>
        </Box>
    )
}

export default ForgotPassword;