import { Paper, Typography, Box, Stepper, Step, StepLabel, Divider, Grid, ImageList, ImageListItem, StepContent, Button, TextField, Table, TableBody, TableRow, TableCell, Modal } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/pageLayout/pageLayout';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Sync from '@mui/icons-material/Sync';
import Check from '@mui/icons-material/Check';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Notes from './notes';

import{ init } from 'emailjs-com';
import * as emailjs from 'emailjs-com';

init(process.env.REACT_APP_EMAILJS_KEY);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '30px',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    boxShadow: 24
}

function OpenOrder(props) {
    const id = props.match.params.id;
    const [order, setOrder] = useState({});
    const [dispatched, setDispatched] = useState('');
    const [status, setStatus] = useState('');
    const [orientation, setOrientation] = useState('vertical');
    const [displayReopen, setDisplayReopen] = useState(false);
    const [note, setNote] = useState('');
    const history = useHistory();
    const [reason, setReason] = useState('');

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const steps = {
        'New': {
            
        },
        'Accepted': {
            icon: <HourglassEmpty />
        },
        'Dispatched': {
            icon: <LocalShipping />
        }, 
        'In Progress': {
            icon: <Sync />
        }, 
        'Completed': {
            icon: <Check />
        }
    }

    useEffect(() => {
        // change orientation of stepper on larger screens
        if(window.innerWidth > 950) setOrientation('horizontal');

        // get order from database
        axios.get(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/getone?id=${id}&databaseId=tbdispatch`)
            .then(res => {
                setOrder(res.data[0]);
                let orderStatus = res.data[0].history[res.data[0].history.length-1];
                
                setStatus(orderStatus);

                if(orderStatus.status === 'Dispatched') {
                    let time = new Date(orderStatus.time);
                    setDispatched(`${time.toLocaleDateString()} at ${time.toLocaleTimeString()}`);
                }
            })
    }, [])

    function cancelOrder() {
        const { location, serviceType, issue, id, urgency } = order;

        let body = {
            status: 'Cancelled',
            eta: '',
            note: reason,
            assignedSub: ''
        }

        // update status of order to Cancelled
        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatestatus?id=${order.id}&databaseId=tbdispatch`, body)
            .then(res => {
                //if(res.statusText === 'OK') history.push('/orders');
                // else setUpdateSuccess('failed')
                // archive order instead of deleting it
                axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/archive?id=${id}&databaseId=tbdispatch&containerId=orders`, {bool: true})
                    .then(res => {
                        if(res.statusText === 'OK') {
                            // close modal
                            handleClose();

                            // construct list of emails to notify when note is added to order
                            axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/neworderemails?containerId=users&databaseId=tbdispatch', {location: location}).then(res => {
                                // do not notify subcontractors
                                let users = res.data.filter(user => user.role !== 'Subcontractor').map(user => {
                                    return {
                                        email: user.email,
                                        name: user.name
                                    }
                                })

                                let emails = '';

                                // send email to each user that is assigned location
                                users.forEach((user, index) => { 
                                    emails = emails += `${user.email},`
                                })

                                let templateParams = {
                                    location: location,
                                    to_email: emails,
                                    serviceType: serviceType,
                                    issue: issue,
                                    order_number: id,
                                    note: note,
                                    urgency: urgency,
                                    company: 'Black Rock Coffee',
                                    reason: reason
                                }

                                emailjs.send(
                                    'service_gekurtf',
                                    'template_wzufhgm', // CANCELLED ORDER TEMPLATE
                                        templateParams,
                                        process.env.REACT_APP_EMAILJS_KEY
                                    )

                                history.push('/orders');
                            })
                        }

                        else {
                            alert('Oops! Something went wrong. Please try again')
                        }
                    })



            })

        
    }

    function sendNotifications(note) {
        const { location, serviceType, issue, id } = order;

        // construct list of emails to notify when note is added to order
        axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/neworderemails?containerId=users&databaseId=tbdispatch', {location: location})
            .then(res => {
                console.log(res.data)
                let users = res.data.filter(user => user.role !== 'Subcontractor').map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    }   
                });

                let emails = '';

                // send email to each user that is assigned location
                users.forEach((user, index) => { 
                    emails = emails += `${user.email},`
                })

                let templateParams = {
                    location: location,
                    to_email: emails,
                    serviceType: serviceType,
                    issue: issue,
                    order_number: id,
                    note: note,
                    company: 'Black Rock Coffee'
                }

                emailjs.send(
                    'service_gekurtf',
                    'template_qe56qd3', //CONTACT TEMPLATE
                        templateParams,
                        process.env.REACT_APP_EMAILJS_KEY
                    )
            })
    }

    function reopenOrder() {
        let body = {
            status: 'Reopened',
            eta: '',
            note: note,
            assignedSub: ''
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatestatus?id=${order.id}&databaseId=tbdispatch`, body)
            .then(res => {
                if(res.statusText === 'OK') history.push('/orders');
                // else setUpdateSuccess('failed')
            })

        sendNotifications(`Order has been reopened: ${note}`);
    }

    return(
        <PageLayout>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography>We are working on dispatching someone to your store. Are you sure you want to cancel this order?</Typography>
                    <TextField
                        fullWidth
                        multiline
                        value={reason}
                        minRows={2}
                        sx={{mt:2}}
                        label='Tell Us Why'
                        onChange={(e) => setReason(e.target.value)}
                    />
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 3}}>
                        <Button
                            sx={{fontSize: '11px'}}
                            color='success'
                            onClick={cancelOrder}
                            variant='contained'
                        >
                            yes, cancel 
                        </Button>
                        <Button
                            sx={{fontSize: '11px', ml: 1}}
                            color='error'
                            onClick={handleClose}
                            variant='contained'
                        >
                            no, keep it
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {Object.keys(order).length > 0 && 
                <>
                    <Paper sx={{m: 3}}>
                        <Box sx={{p: {xs: 3, md: 5}}}>
                            <Typography variant='h5' mb={2}>Order Details:</Typography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            Order #:
                                        </TableCell>
                                        <TableCell>
                                            {order.id}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            Order Placed:
                                        </TableCell>
                                        <TableCell sx={{pr: 0}}>
                                            {new Date(order.date).toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            Order Status:
                                        </TableCell>
                                        <TableCell sx={{pr: 0}}>
                                            {dispatched.length > 0 
                                                ?
                                                    <>
                                                        Dispatched on {dispatched}
                                                    </>
                                                :   `${status.status} - ${new Date(status.time).toLocaleDateString()}`
                                            }
                                            {status.status === 'Completed' &&
                                                <>
                                                    {!displayReopen && 
                                                        <Button
                                                            variant='contained'
                                                            sx={{
                                                                fontSize: '11px',
                                                                ml: 3
                                                            }}
                                                            onClick={() => setDisplayReopen(true)}
                                                        >
                                                            REOPEN
                                                        </Button>
                                                    }
                                                    {displayReopen &&
                                                        <>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    ml: 3
                                                                }}
                                                                
                                                                onClick={reopenOrder}
                                                            >
                                                                SEND
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    ml: 1
                                                                }}
                                                                color='success'
                                                                onClick={() => setDisplayReopen(false)}
                                                            >
                                                                cancel
                                                            </Button>
                                                            <TextField
                                                                sx={{mt: 1}}
                                                                fullWidth
                                                                multiline
                                                                label='Why would you like to reopen this order?'
                                                                onChange={(e) => setNote(e.target.value)}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            Service and Issue:
                                        </TableCell>
                                        <TableCell sx={{pr: 0}}>
                                            {order.serviceType}<br />
                                            {order.issue}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl:0}}>
                                            <b>URGENCY</b>
                                        </TableCell>
                                        <TableCell sx={{pr: 0}}>
                                            {order.urgency || 'Not Specified'}
                                            <Button
                                                color='error'
                                                onClick={handleOpen}
                                                sx={{fontSize: '11px', ml: 2}}
                                                variant='contained'
                                            >
                                                cancel order
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Paper>
                    <Paper sx={{m: 3}}>
                        <Box sx={{p: {xs: 3, md: 5}}}>
                            <Typography variant='h5' mb={2}>Site Details:</Typography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            Location:
                                        </TableCell>
                                        <TableCell>
                                            {order.location}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            On Site Contact Name:
                                        </TableCell>
                                        <TableCell sx={{pr: 0}}>
                                            {order.contact.name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            On-Site Contact Phone:
                                        </TableCell>
                                        <TableCell sx={{pr: 0}}>
                                            {order.contact.phone}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{pl: 0}}>
                                            On-Site Contact Email:
                                        </TableCell>
                                        <TableCell sx={{pr: 0}}>
                                            {order.contact.email}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Paper>
                    <Paper sx={{m: 3}}>
                        <Box sx={{p: {xs: 3, md: 5}}}>
                            <Stepper 
                                orientation={orientation}
                                sx={{
                                    mx: 'auto'
                                }}
                                
                                // get the last status in order history
                                activeStep={Object.keys(steps).indexOf(status.status)} 
                            >
                                {Object.keys(steps).map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                        {label === 'Dispatched' && 
                                            <StepContent>
                                                <small>ETA: {new Date(order.eta).toLocaleString(navigator.language, {weekday: 'short', month: 'numeric', day: 'numeric', year: '2-digit', hour: '2-digit', minute:'2-digit'})}</small>
                                            </StepContent>
                                        }
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Paper>
                    <Paper sx={{m: 3}}>
                        <Box sx={{p: {xs: 3, md: 5}}}>
                            <Notes id={id} notes={order.notes} order={order} />
                        </Box>
                    </Paper>
                    <Paper sx={{m: 3}}>
                        <Box sx={{p: {xs: 3, md: 5}}}>
                            <Typography variant='h5'>Images:</Typography>
                            <ImageList sx={{ width: '100%' }} cols={2} rowHeight={164}>
                                {order.pictures.map((imageSrc, index) => (
                                    <ImageListItem key={imageSrc}>
                                        <img
                                            src={imageSrc}
                                            srcSet={imageSrc}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>

                            {order.pictures.length === 0 &&
                                <Typography
                                    sx={{color: 'gray', width: '100%', textAlign: 'center', mb: 3}}
                                >
                                    NO IMAGES TO DISPLAY
                                </Typography>
                            }
                        </Box>
                    </Paper>
                </>
            }
        </PageLayout>
    )
}

export default OpenOrder;