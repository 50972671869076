import { ArrowBack, Clear } from '@mui/icons-material';
import { Button, Divider, Table, TableBody, TextField, Typography, ImageList, ImageListItem, ImageListItemBar, IconButton, FormGroup, FormControlLabel, Checkbox, Box, dividerClasses, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import SubLayout from '../../components/subLayout/subLayout';
import toArrayBuffer from 'to-array-buffer';
import SignaturePad from 'react-signature-canvas';
import Completed from './completed';
import{ init } from 'emailjs-com';
import * as emailjs from 'emailjs-com';
import { useHistory } from 'react-router-dom';
import TableComponent from '../../components/tableRow/tableRow';
import SubComponent from '../../components/subComponent/subComponent';
import { v4 as uuidv4 } from 'uuid';

init(process.env.REACT_APP_EMAILJS_KEY);

const { BlobServiceClient } = require('@azure/storage-blob');

function OpenSub(props) {
    const history = useHistory();
    const { id } = props.match.params;
    const [order, setOrder] = useState({});
    const [imageArr, setImageArr] = useState([]);
    const [fileArr, setFileArr] = useState([]);
    const [displaySig, setDisplaySig] = useState(false);
    const [signed, setSigned] = useState(false);
    const [status, setStatus] = useState('');
    const [invoiceArr, setInvoiceArr] = useState([]);
    const [invoiceSubmitted, setInvoiceSubmitted] = useState(false);
    const [invoiceLinks, setInvoiceLinks] = useState([]);
    const [ comment, setComment ] = useState({
        date: '',
        comment: ''
    });
    const [displayComment, setDisplayComment] = useState(false);
    const [commentArr, setCommentArr] = useState([]);

    const [displaySubmit, setDisplaySubmit] = useState(false);

    const [ subUpdates, setSubUpdates ] = useState({
        notes: '',
        pictures: [],
        managerComplete: false,
        subComplete: false,
        managerSig: '',
        manager: '',
        invoice: ''
    });

    const sigCanvas = useRef({});

    function managerSignOff() {
        setDisplaySig(!displaySig);
        setSubUpdates({...subUpdates, managerComplete: !subUpdates.managerComplete})
    }

    const blobSasUrl = 'https://tbdispatchstorage.blob.core.windows.net/?sv=2020-08-04&ss=b&srt=co&sp=rwactfx&se=2023-01-01T01:21:05Z&st=2022-04-01T16:21:05Z&spr=https&sig=nBwK190mE4bIvL6UYBboMGRxu89zeAQJVPdaCgtzXUU%3D';

    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerName = 'orderimages';
    const containerClient = blobServiceClient.getContainerClient(containerName);

    useEffect(() => {
        // get order from database
        axios.get(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/getone?id=${id}&databaseId=tbdispatch`)
            .then(res => {
                // update state
                setOrder(res.data[0]);

                setSubUpdates(res.data[0].subUpdates);
                if(res.data[0].subUpdates.managerSig.length > 0) {
                    setSigned(true);
                }
                setStatus(res.data[0].history[res.data[0].history.length - 1].status);

                if(res.data[0].subComments) {
                    setCommentArr(res.data[0].subComments);
                }

                if(res.data[0].subUpdates.invoice.length === 0) {
                    setDisplaySubmit(true)
                }
            })
    }, [])

    function sendNotifications(order, note) {
        const { location, serviceType, issue, id } = order;

        // construct list of emails to notify when note is added to order
        axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/neworderemails?containerId=users?&databaseId=tbdispatch', {location: location})
            .then(res => {
                console.log(res.data)
                let users = res.data.filter(user => user.role !== 'Subcontractor').map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    }   
                });

                let emails = '';

                // send email to each user that is assigned location
                users.forEach((user, index) => { 
                    emails = emails += `${user.email},`
                })

                let templateParams = {
                    location: location,
                    to_email: emails,
                    serviceType: serviceType,
                    issue: issue,
                    order_number: id,
                    note: note.note,
                    company: 'Black Rock Coffee'
                }

                emailjs.send(
                    'service_gekurtf',
                    'template_qe56qd3', //CONTACT TEMPLATE
                        templateParams,
                        process.env.REACT_APP_EMAILJS_KEY
                    )
            })
    }

    // function to upload multiple images at the same time
    function handleImageChange(e) {
        e.preventDefault();

        let files = Array.from(e.target.files);

        let tempFileArr = [];
        let tempImageArr = [];

        files.forEach((file, index) => {
            let reader = new FileReader();
            
            reader.onload = () => {
                var arrBuff = toArrayBuffer(reader.result);
                
                tempFileArr.push({name: file.name, buffer: arrBuff});
                tempImageArr.push(reader.result);

                // set image array and file array if at the end of the file array
                if(index === files.length - 1) {
                    setFileArr(tempFileArr);
                    setImageArr(tempImageArr);
                    setSubUpdates({...subUpdates, pictures: tempImageArr})
                }

                // download images to phone or computer
                //creating an invisible element
                var element = document.createElement('a');
                element.setAttribute('href', `${reader.result}`);
                element.setAttribute('download', `${file.name}`);
            
                document.body.appendChild(element);
                
                //onClick property
                element.click();
            
                document.body.removeChild(element);
            }

            reader.readAsDataURL(file)
        });
    }

    function submitInvoice() {
        setInvoiceSubmitted(true);

        let user = order.assignedSub.name;

        let note = {
            user: user,
            date: new Date(),
            note: `Subcontractor has uploaded an invoice.`,
            company: 'BR Coffee'
        }

        // // add new note to open order
        // axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatenotes?id=${id}&databaseId=tbdispatch`, note)
        // .then(res => {
        //     sendNotifications(order, note);
        // })

        let urlArr = [];

        setInvoiceLinks(urlArr);

        // save images to blob storage using their array buffer
        invoiceArr.forEach(file => {
            let url = `https://tbdispatchstorage.blob.core.windows.net/orderimages/${file.name}`
            urlArr.push(url);
            const blockBlobClient = containerClient.getBlockBlobClient(`${file.name}`);
            blockBlobClient.uploadBrowserData(file.buffer);
        })

        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/subupdate?id=${id}&databaseId=tbdispatch`, {
            notes: subUpdates.notes,
            pictures: subUpdates.pictures,
            date: subUpdates.date,
            subComplete: subUpdates.subComplete,
            managerComplete: subUpdates.managerComplete,
            manager: subUpdates.manager,
            invoice: urlArr,
            managerSig: subUpdates.managerSig
        }).then(res => {
            // redirect user to subportal
            // if(res.statusText === 'OK') {
            //     history.push('/subportal')
            // }
            setDisplaySubmit(false);
        })
    }

    function handleFileUpload(e) {
        e.preventDefault();

        let files = Array.from(e.target.files);

        let tempInvoiceArr = [];
        let uuid = uuidv4();
        let urlArr = [];

        files.forEach((file, index) => {
            let reader = new FileReader();
            
            reader.onload = () => {
                var arrBuff = toArrayBuffer(reader.result);

                tempInvoiceArr.push({name: `${uuid}${file.name}`, buffer: arrBuff, result: reader.result});
                urlArr.push(`https://tbdispatchstorage.blob.core.windows.net/orderimages/${uuid}${file.name}`)
                //tempImageArr.push(reader.result);

                // set image array and file array if at the end of the file array
                if(index === files.length - 1) {
                    setInvoiceArr(tempInvoiceArr);
                    setSubUpdates({
                        ...subUpdates, 
                        invoice: urlArr
                    })
                }
            }

            reader.readAsDataURL(file)
        });
    }

    function submit() {
        // get logged in sub information
        let user = order.assignedSub.name;

        let note = {
            user: user,
            date: new Date(),
            note: `Manager ${subUpdates.manager} has signed off on work completion.`,
            company: 'BR Coffee'
        }

        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatenotes?id=${id}&databaseId=tbdispatch`, note)
        .then(res => {
            //sendNotifications(order, note);
        })
        
        let uuid = uuidv4();
        let urlArr = [];
        for(let i=0; i<fileArr.length; i++) {
            let url = `https://tbdispatchstorage.blob.core.windows.net/orderimages/${uuid}${fileArr[i].name}`
            urlArr.push(url);
        }
        
        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/subupdate?id=${id}&databaseId=tbdispatch`, {
            notes: subUpdates.notes,
            pictures: urlArr,
            date: new Date(),
            subComplete: subUpdates.subComplete,
            managerComplete: subUpdates.managerComplete,
            manager: subUpdates.manager,
            invoice: subUpdates.invoice,
            managerSig: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        }).then(res => {
            // redirect user to subportal
            if(res.statusText === 'OK') {
                history.push('/subportal')
            }
        })

        let body = {
            status: 'Completed',
            eta: '',
            note: 'Subcontractor submitted completion form',
            assignedSub: ''
        }

        // update status of order as complete
        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatestatus?id=${id}&databaseId=tbdispatch`, body)
            .then(res => {
                //if(res.statusText === 'OK') history.push('/orders');
                // else setUpdateSuccess('failed')
            })

        // save images to blob storage using their array buffer
        fileArr.forEach(file => {
            const blockBlobClient = containerClient.getBlockBlobClient(`${uuid}${file.name}`);
            blockBlobClient.uploadBrowserData(file.buffer);
        })
    }

    function removeImage(index) {
        let tempImageArr = imageArr;
        let tempFileArr = fileArr;
        tempImageArr.splice(index, 1);
        tempFileArr.splice(index, 1);
        setImageArr([...tempImageArr]);
        setSubUpdates({...subUpdates, pictures: tempImageArr});
        setFileArr([...tempFileArr]);
    }

    function saveComment() {

        // hide text area
        setDisplayComment(false);

        // time stamp comment
        comment.date = new Date().getTime();

        // update local array of comments
        setCommentArr([comment, ...commentArr]);

        // save comment into work order
        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/subcomments?databaseId=tbdispatch&id=${id}`, comment)
            .then(res => {
                console.log(res)
            })

        // construct note to add to order
        let user = order.assignedSub.name;
        let note = {
            user: user,
            date: new Date(),
            note: `Subcontractor has added a comment to the order: ${comment.comment}.`,
            company: 'BR Coffee'
        }

        // add new note to open order
        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatenotes?id=${id}&databaseId=tbdispatch`, note)
        .then(res => {
            //sendNotifications(order, note);
        })
    }

    function arrived() {
        let body = {
            status: 'In Progress',
            eta: '',
            note: 'Subcontractor has arrived at location',
            assignedSub: ''
        }

        // update status of order as complete
        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatestatus?id=${id}&databaseId=tbdispatch`, body)
            .then(res => {
                // update status of open order
                setStatus('In Progress')
            })
    }

    return(
        <SubLayout>
            <Button component='a' href='/subportal' startIcon={<ArrowBack /> } sx={{m: 2}}>
                Back to orders
            </Button>
            {Object.keys(order).length > 0 &&
                <>
                    <SubComponent>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant='h6'>
                                    Order Details:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button
                                        sx={{fontSize: '10px'}}
                                        variant='contained'
                                        onClick={arrived}
                                        disabled={status === 'In Progress'}
                                    >
                                        Arrived
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 1}} />
                        <Table sx={{px: 0}}>
                            <TableBody>
                                <TableComponent>
                                    Order #:
                                    {id}
                                </TableComponent>
                                <TableComponent>
                                    Location:
                                    {order.location}
                                </TableComponent>
                                <TableComponent>
                                    Status:
                                    {status}
                                </TableComponent>
                                <TableComponent>
                                    ServiceType:
                                    {order.serviceType}
                                </TableComponent>
                                <TableComponent>
                                    Issue:
                                    {order.issue}
                                </TableComponent>
                                {order.noteToSub &&
                                    <TableComponent>
                                        <b>Note from Admin team:</b>
                                        {order.noteToSub}
                                    </TableComponent>
                                }
                            </TableBody>
                        </Table>
                    </SubComponent>
                    {order.subUpdates.date.length > 0 &&
                        <SubComponent>
                            <Completed order={order} />
                            <Divider sx={{my: 3}} />
                            {!invoiceSubmitted && displaySubmit &&
                                <>
                                    <Typography variant='h6'>Upload Invoice:</Typography>
                                    <input type='file'
                                        onChange={handleFileUpload}
                                        multiple
                                    />
                                    <br />
                                    <Button
                                        variant='contained'
                                        sx={{fontSize: '12px', mt: 3}}
                                        onClick={submitInvoice}
                                    >
                                        submit invoice
                                    </Button>
                                </>
                            }

                            {invoiceSubmitted &&
                                invoiceLinks.map(link => {
                                    return(
                                        <a href={link} key={link}>Invoice</a>
                                    )
                                })
                            }

                            {/* {!invoiceSubmitted && subUpdates.invoice.length > 0 &&
                                <>
                                    <Typography variant='h6'>Your Invoice:</Typography>
                                    {subUpdates.invoice.map(link => {
                                        return(
                                            <div key={link}>
                                                <a href={link}>Invoice</a>
                                                <br />
                                            </div>
                                        )
                                    })}
                                </>
                            }          */}
                        </SubComponent>
                    }
                    {order.subUpdates.date.length === 0 &&
                        <>
                            <SubComponent>
                                <Typography variant='h6'>Comments to our team:</Typography>
                                <Typography variant='body2'>Need to get in touch with our team before the work is complete? Leave a comment here and someone will contact you shortly</Typography>
                                {commentArr.length > 0 &&
                                    commentArr.map(comment => {
                                        return(
                                            <>
                                                <Divider sx={{my: 1}}/>
                                                <Typography>{new Date(comment.date).toLocaleString()}</Typography>
                                                <Typography variant='body2'>{comment.comment}</Typography>
                                            </>
                                        )
                                    })
                                }
                                {!displayComment &&
                                    <Button
                                        variant='contained'
                                        sx={{fontSize: '12px', mt: 3}}
                                        onClick={() => setDisplayComment(true)}
                                    >
                                        write a comment
                                    </Button>
                                }
                                {displayComment &&
                                    <>
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            sx={{my: 3}}
                                            value={comment.comment}
                                            onChange={(e) => setComment({...comment, comment: e.target.value})}
                                        />
                                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'right'}}>
                                            <Button
                                                variant='contained'
                                                color='success'
                                                sx={{fontSize: '12px'}}
                                                onClick={saveComment}
                                            >
                                                send
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='error'
                                                sx={{fontSize: '12px', ml: 1}}
                                                onClick={() => setDisplayComment(false)}
                                            >
                                                cancel
                                            </Button>
                                        </Box>
                                    </>
                                }
                            </SubComponent>
                            <SubComponent>
                                <Typography variant='h6'>
                                    Is the work complete?
                                </Typography>
                                <Typography variant='body2'>
                                    Please fill out the form below and have the on site manager sign off on work completed. All fields are required.
                                </Typography>

                                <TextField
                                    sx={{mt: 2}}
                                    type='file'
                                    fullWidth
                                    inputProps={{multiple: true}}
                                    variant='outlined'
                                    onChange={handleImageChange}
                                />

                                <ImageList sx={{ width: '100%' }} cols={2} rowHeight={164}>
                                    {subUpdates.pictures.length > 0 && subUpdates.pictures.map((imageSrc, index) => (
                                        <ImageListItem key={imageSrc}>
                                            <img
                                                src={imageSrc}
                                                srcSet={imageSrc}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                sx={{
                                                    background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                }}
                                                position="top"
                                                actionIcon={
                                                    <IconButton
                                                        sx={{ color: 'white' }}
                                                        onClick={() => removeImage(index)}
                                                        >
                                                        <Clear />
                                                    </IconButton>
                                                }
                                                actionPosition="right"
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>

                                <TextField
                                    fullWidth
                                    label='Add Notes'
                                    sx={{mb: 3}}
                                    multiline
                                    minRows={4}
                                    value={subUpdates.notes}
                                    onChange={(e) => setSubUpdates({...subUpdates, notes: e.target.value})}
                                />

                                <FormGroup>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                disabled={status === 'Completed' }
                                                onChange={() => setSubUpdates({...subUpdates, subComplete: !subUpdates.subComplete})}
                                                checked={subUpdates.subComplete}
                                            />
                                        }
                                        label='I, the subcontractor, verify that the work is complete' 
                                    />
                                    <FormControlLabel
                                        sx={{mt: 2}}
                                        control={
                                            <Checkbox 
                                                disabled={status === 'Completed' }
                                                onChange={managerSignOff}
                                                checked={subUpdates.managerComplete} 
                                            />
                                        }
                                        label='I, the manager, verify that the work is complete' 
                                    />
                                </FormGroup>

                                {subUpdates.managerComplete &&
                                    <TextField
                                        value={subUpdates.manager}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => setSubUpdates({...subUpdates, manager: e.target.value})}
                                        placeholder='Manager Name'
                                    />
                                }

                                {subUpdates.managerComplete && subUpdates.managerSig.length === 0 &&
                                    <>
                                        <Typography mt={2} mb={1} variant='body2'>Manager Sign Here:</Typography>
                                        <SignaturePad
                                            onEnd={() => setSigned(true)}
                                            ref={sigCanvas}
                                            canvasProps={{
                                                style: {
                                                    border: '1px solid #d9d9d9',
                                                    width: '100%'
                                                }
                                            }}
                                        />
                                        <button onClick={() => sigCanvas.current.clear()}>Clear</button>
                                    </>
                                }

                                {subUpdates.managerSig.length > 0 &&
                                    <img src={subUpdates.managerSig} alt='Managers signature' style={{width: '100%'}} />
                                }

                                <Button
                                    variant='contained'
                                    fullWidth
                                    onClick={submit}
                                    disabled={!subUpdates.managerComplete || !subUpdates.subComplete || subUpdates.manager.length === 0 || subUpdates.pictures.length === 0 || !signed}
                                    sx={{mt: 2}}
                                >
                                    Submit changes
                                </Button>
                            </SubComponent>
                        </>
                    }
                </>
            }
        </SubLayout>
    )
}

export default OpenSub;