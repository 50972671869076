import React from 'react';
import PageLayout from '../../components/pageLayout/pageLayout';
import {List,Paper, Typography, Grid, CircularProgress } from '@mui/material';
import OrdersLogic from './ordersLogic';
import { Box } from '@mui/material';
import OrderList from '../../components/orderList/orderList';

function Orders(props) {
    const { orders, loading } = OrdersLogic(props);
    const { status } = props.match.params;

    const text = {
        'new': 'new',
        'accepted': 'accepted',
        'reopened': 'reopened',
        'progress': 'in progress',
        'dispatched': 'dispatched',
        'completed': 'completed'
    }

    return (
        <PageLayout>
            {loading &&
                <Box
                    sx={{display: 'flex'}}
                    mt={5}
                >
                    <CircularProgress sx={{color: '#fab544', mx: 'auto'}} />
                </Box>
            }

            {!loading && orders.length === 0 &&
                <Box mt={5} sx={{width: '90%', maxWidth: '300px', margin: '0 auto', paddingTop: '40px'}}>
                    <Typography variant='h5' style={{width: '100%', textAlign: 'center'}}>
                        Oops!
                    </Typography>
                    <Typography variant='body1' mt={1} style={{width: '100%', textAlign: 'center'}}>
                        We could not find any {text[status]} orders. Please select a different filter.
                    </Typography>
                </Box>
            }
            {!loading && orders.length > 0 &&
                <Paper>
                    <Grid container px={3} py={2} sx={{bgcolor: '#f2f2f2', my: 'auto'}}>
                        <Grid item xs={9} sx={{my: 'auto'}}>
                            <Typography variant='body1'>
                                Work Orders
                            </Typography>
                        </Grid>
                    </Grid>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {orders.length > 0 &&
                            <OrderList orders={orders} />
                        }
                    </List>
                </Paper>
            }
        </PageLayout>
    )
}

export default Orders;