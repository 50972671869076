import React from "react";
import { TableRow, TableCell } from "@mui/material";

function TableComponent(props) {
    const { children } = props;
    return(
        <TableRow>
            <TableCell sx={{pl: 0}}>
                {children[0]}
            </TableCell>
            <TableCell sx={{pr: 0}}>
                {children[1]}
            </TableCell>
        </TableRow>
    )
}

export default TableComponent;