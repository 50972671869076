import React, { useState, useEffect } from 'react';
import { Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, Box, Pagination } from '@mui/material';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Sync from '@mui/icons-material/Sync';
import Check from '@mui/icons-material/Check';
import { OpenInNew } from '@mui/icons-material';

function OrderList(props) {
    const { orders } = props;
    const [orderSubset, setOrderSubset] = useState([]);
    const [numPages, setNumPages] = useState(0);

    let count = 10;

    useEffect(() => {
        setOrderSubset(orders.slice(0, count));

        // get the number of pages of orders
        setNumPages(Math.ceil(orders.length / count));
    }, [orders]);

    function changePage(page) { 
        if(page === 1) {
            setOrderSubset(orders.slice(0, count));
        }

        else if(page === numPages) {
            console.log(orders.slice((page-1)*count, orders.length))
            setOrderSubset(orders.slice((page-1)*count, orders.length))
        }

        else {
            setOrderSubset(orders.slice((page-1) * count, page*count));
        }
    }

    const iconStyle = {
        fontSize: '10px',
        color: '#6c757d'
    }

    let statuses = {
        'New': 'New',
        'Reopened': <OpenInNew />,
        'Completed': <Check />,
        'In Progress': <Sync />,
        'Dispatched': <LocalShipping />,
        'Accepted': <HourglassEmpty />,
    }

    return(
        <>
            <List sx={{ width: '100%', bgcolor: 'background.paper', py: 0 }}>
                {orderSubset.length > 0 &&
                    orderSubset.map(order => {
                        let date = new Date(order.date);
                        let today = new Date();
                        let displayDate;

                        // get the last order status update in history array
                        let statusIndex = order.history.length - 1;

                        let status = order.history[statusIndex].status;

                        // display time if order was sent today
                        if(`${date.getMonth()} ${date.getDate()}` === `${today.getMonth()} ${today.getDate()}`) {
                            displayDate = date.toLocaleTimeString()
                        }

                        // otherwise display month, day, and year
                        else {
                            displayDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
                        }
                        
                        return(
                            <div key={order.id}>
                                <ListItem
                                    component='a'
                                    href={`/orders/${order.id}`}
                                    sx={{
                                        color: 'black !important',
                                        pl: 0,
                                        py: 0
                                    }}
                                >
                                    <ListItemButton>
                                        <ListItemAvatar sx={{textAlign: 'center'}}>
                                            {status !== 'New' && statuses[status]}
                                            <Typography mx='auto' sx={status === 'New' ? '' : iconStyle}>{status}</Typography>
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={order.serviceType}
                                            secondary={
                                                <>
                                                    <Typography>
                                                    <small> <i>{order.id}</i></small> - {order.issue.length > 70 ? `${order.issue.substring(0, 70)}...` : order.issue}
                                                    </Typography>
                                                
                                                </>
                                            }
                                            sx={{
                                                width: '70%',
                                                display: { xs: 'none', sm: 'block' },
                                                pl: 3
                                            }} />
                                        <ListItemText 
                                            primary={order.serviceType}
                                            sx={{
                                                width: '50%',
                                                display: { xs: 'block', sm: 'none' },
                                                pl: 3
                                            }} />
                                        <ListItemText
                                            sx={{ml: ' 0 auto'}}
                                            secondary={displayDate}
                                        >
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider py={0} />
                            </div>
                        )
                    })
                }
            </List>
            <Box sx={{width: '100%', textAlign: 'center', pt: 3, pb: 2}} display='flex' justifyContent="center">
                <Pagination 
                    count={numPages} 
                    variant='outlined' 
                    shape='rounded' 
                    onChange={(e, page) => changePage(page)}
                />
            </Box>
        </>
    )
}

export default OrderList;