import axios from 'axios';

import{ init } from 'emailjs-com';
import * as emailjs from 'emailjs-com'
init(process.env.REACT_APP_EMAILJS_KEY);

function FormLogic() {
    function notifyStoreMgr(formValues) {
        const { serviceType, issue, location, urgency } = formValues;

        // get email and name of location
        axios.get('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/getall?containerId=locations&databaseId=tbdispatch')
        .then(res => {
            let locationObj = res.data.filter(loc => loc.name === location)
            let templateParams = {
                to_email: locationObj[0].email,
                to_name: locationObj[0].contact,
                issue: issue,
                serviceType: serviceType,
                location: location,
                urgency: urgency
            }

            emailjs.send(
                'service_gekurtf',
                'template_o8b7q1a', //CONTACT TEMPLATE
                    templateParams,
                    process.env.REACT_APP_EMAILJS_KEY
                )
        })
    }

    async function sendNotifications(formValues) {
        const { serviceType, issue, location, urgency } = formValues;

        // construct list of emails to notify when new order is submitted
        axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/neworderemails?containerId=users&databaseId=tbdispatch', {location: location})
            .then(res => {
                // do not notify subcontractors
                let users = res.data.filter(user => user.role !== 'Subcontractor').map(user => {
                    return {
                        email: user.email,
                        name: user.name
                    }
                })

                let emails = '';

                // send email to each user that is assigned location
                users.forEach((user, index) => { 
                    emails = emails += `${user.email},`
                })

                let templateParams = {
                    to_name: 'test',
                    location: location,
                    to_email: emails,
                    serviceType: serviceType,
                    issue: issue,
                    urgency: urgency
                }

                emailjs.send(
                    'service_gekurtf',
                    'template_gel21xx', //CONTACT TEMPLATE
                        templateParams,
                        process.env.REACT_APP_EMAILJS_KEY
                    )
            })
    }

    return {
        sendNotifications,
        notifyStoreMgr
    }
}

export default FormLogic;