import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import LoginLogic from './loginLogic';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

function Subcontractor(props) {
    const {
        setLoginValues,
        loginValues,
        errors,
        loginSub
    } = LoginLogic();

    const { setDisplaySubForm } = props;

    return(
        <Box
            p={4}
            pt={3}
        >
            <Button
                startIcon={<ArrowBack /> }
                sx={{mb: 3, p: 0}}
                onClick={() => setDisplaySubForm(false)}
            >
                Back to Login
            </Button>
            <TextField
                error={errors.email.length > 0 ? true : false}
                fullWidth
                variant='standard'
                label='Email'
                sx={{mb: 2}}
                value={loginValues.email}
                onChange={(e) => setLoginValues({...loginValues, email: e.target.value})}
                helperText={errors.email}
                onKeyDown={(event) => {
                    if(event.code === 'Enter') {
                        loginSub();
                    }
                }}
            />

            <Box textAlign='center'>
                <Button
                    variant='contained'
                    sx={{width: '100%', mt: 3, bgcolor: '#fab544',  '&:hover': {
                        backgroundColor: '#a6192e !important',
                    },}}
                    onClick={loginSub}
                >
                    Login
                </Button>
            </Box>
       </Box>
    )
}

export default Subcontractor;