import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

function OrdersLogic(props) {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    let { status } = props.match.params;
    const history = useHistory();

    useEffect(() => {
        // get user info from local storage to see which user is logged in
        let userInfo = JSON.parse(localStorage.getItem('user'));
        let {locations} = userInfo;

        let statuses = {
            'accepted': 'Accepted',
            'progress': 'In Progress',
            'dispatched': 'Dispatched',
            'completed': 'Completed',
            'new': 'New',
            'reopened': 'Reopened'
        }
        
        // get all orders from tbdispatch database using azure function app function
        axios.get('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/getall?containerId=orders&databaseId=tbdispatch')
            .then(res => {
                // don't filter order results by default
                let userOrders = res.data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

                // filter orders based on locations that are assigned to user that is logged in if user is not in Admin role
                if(userInfo.role !== 'Admin') {
                    userOrders = userOrders.filter(order => locations.indexOf(order.location) > -1);
                }

                // retrieve only the archived work orders
                if(status === 'archived') {
                    setOrders(userOrders.filter(order => order.archived))
                }

                // filter orders based on status if status is in url params
                else if(Object.keys(statuses).indexOf(status) !== -1) {
                    setOrders(userOrders.filter(order => order.history[order.history.length-1].status === statuses[status] && !order.archived))
                }

                else {
                    setOrders(userOrders.filter(order => !order.archived))
                }

                setLoading(false)

                // redirect user to new order page if there are no orders in the database to display
                if(!status && userOrders.length === 0) {
                    history.push('/neworder')
                }
            })
    }, [])

    return {
        orders,
        loading
    }
}

export default OrdersLogic;