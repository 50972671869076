import React from 'react';
import LoginLogic from './loginLogic';
import { Box, Button, Typography, TextField} from '@mui/material';

function ChangePassword(props) {
    const {
        setLoginValues,
        updatePassword,
        errors,
        loginValues
    } = LoginLogic();

    const { email } = props;

    return(
        <Box
            p={4}
            pt={2}
        >   
            <Typography variant='body2' mb={2}>
                We see this is your first time logging in with your temporary password. Please change your password for security purposes.
            </Typography>
            <TextField
                fullWidth
                type='password'
                variant='standard'
                label='Password'
                sx={{mb: 2}}
                value={loginValues.password}
                onChange={(e) => setLoginValues({...loginValues, password: e.target.value})}
            />

            <TextField
                error={errors.password2.length > 0 ? true : false}
                fullWidth
                type='password'
                variant='standard'
                label='Retype Password'
                sx={{mb: 2}}
                value={loginValues.password2}
                onChange={(e) => setLoginValues({...loginValues, password2: e.target.value})}
                helperText={errors.password2}
                onKeyDown={(event) => {
                    if(event.code === 'Enter') {
                        updatePassword(email)
                    }
                }}
            />

            <Button 
                sx={{mt: 2}}
                fullWidth
                variant='contained'
                onClick={() => updatePassword(email)}
            >
                CHANGE PASSWORD
            </Button>
        </Box>
    )
}

export default ChangePassword;