import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import br from '../../images/br.png';
import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';

import tb from '../../images/tb.png';

import{ init } from 'emailjs-com';
import * as emailjs from 'emailjs-com';

init(process.env.REACT_APP_EMAILJS_KEY);

function Notes(props) {
    const { id, order } = props;
    const [notes, setNotes] = useState(props.notes);
    const [note, setNote] = useState('');
    const [displayForm, setDisplayForm] = useState(false);

    function sendNotifications(order, note) {
        const { location, serviceType, issue, id } = order;

        // construct list of emails to notify when note is added to order
        axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/neworderemails?containerId=users&databaseId=tbdispatch', {location: location})
            .then(res => {
               // do not notify subcontractors
               let users = res.data.filter(user => user.role !== 'Subcontractor').map(user => {
                return {
                    email: user.email,
                    name: user.name
                }
            })

                let emails = '';

                // send email to each user that is assigned location
                users.forEach((user, index) => { 
                    emails = emails += `${user.email},`
                })

                let templateParams = {
                    location: location,
                    to_email: emails,
                    serviceType: serviceType,
                    issue: issue,
                    order_number: id,
                    note: note,
                    company: 'Black Rock Coffee'
                }

                emailjs.send(
                    'service_gekurtf',
                    'template_qe56qd3', //NEW NOTE TEMPLATE
                        templateParams,
                        process.env.REACT_APP_EMAILJS_KEY
                    )
            })
    }

    function updateOrder() {
        // get logged in user information
        let user = JSON.parse(localStorage.getItem('user')).name;

        let body = {
            user: user,
            date: new Date(),
            note: note,
            company: 'BR Coffee'
        }
  
        setDisplayForm(false);
        setNotes([body, ...notes]);
        
        axios.post(`https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatenotes?id=${id}&databaseId=tbdispatch`, body)
            .then(res => {
                //sendNotifications(order, note);
            })
    }

    return(
        <>
            <Box sx={{display: 'flex', position: 'relative', mb: 2}}>
                <Typography
                    variant='h5'
                >
                    Notes:
                </Typography>

                <Divider />

                {!displayForm && <Button
                    style={{right: '0px', position: 'absolute', backgroundColor: '#0b56a4', color: 'white', fontSize: '12px'}}
                    onClick={() => setDisplayForm(true)}
                >
                    <AddCircleOutlineIcon sx={{fontSize: '14px', mr: 1}} /> New Note
                </Button>}

                {displayForm &&
                    <Box sx={{position: 'absolute', right: '0px', zIndex: 5}}>
                        <Button
                            sx={{bgcolor: 'green',fontSize: '12px', color: 'white'}}
                            onClick={updateOrder}
                        >
                            save
                        </Button>
                        <Button
                            sx={{bgcolor: 'red', fontSize: '12px', ml: 1, color: 'white'}}
                            onClick={() => setDisplayForm(false)}
                        >
                            discard
                        </Button>
                    </Box>
                }
            </Box>

            {displayForm && 
                <Box>
                    <form>
                        <TextField
                            fullWidth
                            multiline
                            onChangeCapture={(e) => setNote(e.target.value)}
                        />
                        
                    </form>
                </Box>
            }

            {notes.length > 0 && 
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {notes.map(note => {
                        return(
                            <ListItem alignItems="flex-start" key={note.date}>
                                <ListItemAvatar>
                                <Avatar alt={note.company} src={note.company === 'BR Coffee' ? br : tb} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={new Date(note.date).toLocaleString()}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {note.user}
                                            </Typography>
                                            {` — ${note.note}`}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        )
                    })}
                </List>
            }

            {notes.length === 0 && !displayForm &&
                <Typography
                    sx={{color: 'gray', width: '100%', textAlign: 'center', mb: 3}}
                >
                    NO NOTES TO DISPLAY
                </Typography>
            }
        </>
    )
}

export default Notes;