import React, { useEffect, useRef, useState } from 'react';
import order from '../../constructors/order';

import TextField from '@mui/material/TextField';
import { Typography, Box, Button, ImageList, ImageListItem, ImageListItemBar, IconButton, Alert, Select, MenuItem, Menu } from '@mui/material';
import { AccountCircle, Reorder, Clear, Warning } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ConstructionIcon from '@mui/icons-material/Construction';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Autocomplete } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import services from '../../data/services';

import 'regenerator-runtime/runtime';
import toArrayBuffer from 'to-array-buffer';
import axios from 'axios';

import FormLogic from './formLogic';

const { BlobServiceClient } = require('@azure/storage-blob');

function OrderForm() {
    const ref = useRef();

    // set initial values of form
    const [formValues, setFormValues] = useState(order);
    const [fileArr, setFileArr] = useState([]);
    const [imageArr, setImageArr] = useState([]);
    const [locations, setLocations] = useState([]);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(false);

    const {
        sendNotifications,
        notifyStoreMgr
    } = FormLogic()

    const [errors, setErrors] = useState({
        location: '',
        serviceType: '',
        name: '',
        email: '',
        phone: '',
        issue: ''
    })

    useEffect(() => {
        // get list of locations assigned to logged in user
        let user = JSON.parse(localStorage.getItem('user'));
        setLocations(user.locations);
    }, []);

    const blobSasUrl = 'https://tbdispatchstorage.blob.core.windows.net/?sv=2020-08-04&ss=b&srt=co&sp=rwactfx&se=2023-01-01T01:21:05Z&st=2022-04-01T16:21:05Z&spr=https&sig=nBwK190mE4bIvL6UYBboMGRxu89zeAQJVPdaCgtzXUU%3D';

    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerName = 'orderimages';
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // function to upload multiple images at the same time
    function handleImageChange(e) {
        e.preventDefault();

        let files = Array.from(e.target.files);

        let tempFileArr = [];
        let tempImageArr = [];

        files.forEach((file, index) => {
            let reader = new FileReader();
            
            reader.onload = () => {
                var arrBuff = toArrayBuffer(reader.result);
                
                tempFileArr.push({name: file.name, buffer: arrBuff});
                tempImageArr.push(reader.result);

                console.log(tempImageArr)

                // set image array and file array if at the end of the file array
                if(index === files.length - 1) {
                    console.log(index)
                    setFileArr(tempFileArr);
                    setImageArr(tempImageArr);
                }
            }

            reader.readAsDataURL(file)
        });
    }

    function readFileUpload(e) {
        if(e.target.files) {
            const reader = new FileReader();

            let file = e.target.files[0];

            reader.onload = (e) => {
                var arrBuff = toArrayBuffer(reader.result);

                console.log(reader.result)

                setFileArr([...fileArr, {name: file.name, buffer: arrBuff}]);
                setImageArr([...imageArr, reader.result]);
            }

            reader.readAsDataURL(e.target.files[0])
        }
    }

    function validate() {
        if(formValues.location.length === 0 || formValues.serviceType.length === 0 || formValues.contact.name.length === 0 || formValues.contact.email.length === 0 || formValues.contact.phone.length === 0 || formValues.issue.length === 0) {
            setError(true)
            return false
        }

        return true
    }

    async function submit(e) {
        e.preventDefault();

        let noErrors = validate();

        // reset file input to empty
        ref.current.value = '';
        
        if(noErrors) {
            setError(false);
            
            // get current date of order submission
            let time = new Date();
            formValues.history[0].time = time;

            // blank eta key value pair
            formValues.eta = '';
            formValues.notes = [];
            formValues.privateNotes = [];
            formValues.date = time;
            formValues.localTime = new Date().toLocaleTimeString();

            let uuid = uuidv4();

            // generate array of azure blob urls
            let urlArr = [];
            for(let i=0; i<fileArr.length; i++) {
                let url = `https://tbdispatchstorage.blob.core.windows.net/orderimages/${uuid}${fileArr[i].name}`
                urlArr.push(url);
            }

            formValues.pictures = urlArr;

            // generate random id for order
            formValues.id = `BR-${Math.floor(Math.random() * 9000000)}`;

            // save order to database
            axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/save?containerId=orders&databaseId=tbdispatch', formValues)
                .then(res => {
                    if(res.statusText === 'OK') {
                        setSuccess(true);

                        // reset form values to blank
                        setImageArr([]);
                        setFormValues(order);
                    }
                    else {
                        setSuccess(false)
                    }
                })

            // save images to blob storage using their array buffer
            fileArr.forEach(file => {
                const blockBlobClient = containerClient.getBlockBlobClient(`${uuid}${file.name}`);
                blockBlobClient.uploadBrowserData(file.buffer);
            })

            // send email notifications to each user that is assigned location when a new work order is submitted
            sendNotifications(formValues)
            
            // send email notifications to store manager
            //notifyStoreMgr(formValues);
        }
    }

    function removeImage(index) {
        let tempImageArr = imageArr;
        let tempFileArr = fileArr;
        tempImageArr.splice(index, 1);
        tempFileArr.splice(index, 1);
        setImageArr([...tempImageArr]);
        setFileArr([...tempFileArr]);
    }

    return(
        <form>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <LocationOnIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <Autocomplete
                    required
                    size='small'
                    autoComplete
                    fullWidth
                    value={formValues.location}
                    onChange={(e, newValue) => setFormValues({...formValues, location: newValue})}
                    options={locations}
                    renderInput={(params) => 
                        <TextField 
                            {...params} 
                            label='Select Location' 
                            variant='standard'
                            helperText={errors.location}
                            error={errors.location.length > 0 ? true : false} />}
                    
                />
            </Box>

            <Typography
                 variant='h5'
                 mt={4}
            >
                On Site Contact Info
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
                <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                    error={errors.name.length > 0 ? true : false}
                    required
                    size='small'
                    label='Name'
                    fullWidth
                    variant='standard'
                    value={formValues.contact.name}
                    onChange={(e) => setFormValues({...formValues, contact: {...formValues.contact, name: e.target.value}})}
                    helperText={errors.name}
                />
            </Box>

            {/* <img src='https://tbdispatchstorage.blob.core.windows.net/orderimages/test2.jpg' /> */}

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
                <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                    error={errors.email.length > 0 ? true : false}
                    required
                    size='small'
                    label='Email'
                    fullWidth
                    variant='standard'
                    value={formValues.contact.email}
                    onChange={(e) => setFormValues({...formValues, contact: {...formValues.contact, email: e.target.value}})}
                    helperText={errors.email}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
                <PhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                    error={errors.phone.length > 0 ? true : false}
                    required
                    size='small'
                    label='Phone Number'
                    fullWidth
                    variant='standard'
                    value={formValues.contact.phone}
                    onChange={(e) => setFormValues({...formValues, contact: {...formValues.contact, phone: e.target.value}})}
                    helperText={errors.phone}
                />
            </Box>

            <Typography
                 variant='h5'
                 mt={4}
            >
                Description of Work
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
                <ConstructionIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <Autocomplete
                    required
                    fullWidth
                    options={services}
                    autoComplete
                    includeInputInList
                    value={formValues.serviceType}
                    renderInput={(params) => (
                    <TextField {...params} label="Service Type" variant="standard" helperText={errors.serviceType} error={errors.serviceType.length > 0 ? true : false} />
                    )}
                    onChange={(e, newValue) => setFormValues({...formValues, serviceType: newValue})}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
                <Warning sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <Select
                    size='small'
                    variant='standard'
                    required
                    fullWidth
                    includeInputInList
                    label='URGENCY'
                    placeholder='URGENCY'
                    sx={formValues.urgency === '30 minutes' || formValues.urgency === '60 minutes' ? {color: 'red', mt:2} : {mt:2}}
                    value={formValues.urgency}
                    onChange={(e, newValue) => setFormValues({...formValues, urgency: e.target.value})}
                >
                    <MenuItem sx={{color: 'red'}} value='30 minutes'>30 MINUTES</MenuItem>
                    <MenuItem sx={{color: 'red'}} value='60 minutes'>60 MINUTES</MenuItem>
                    <MenuItem value='4 hours'>4 Hours</MenuItem>
                    <MenuItem value='24 hours'>24 Hours</MenuItem>
                    <MenuItem value='5 day'>5 Day +</MenuItem>
                </Select>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 3 }}>
                <Reorder sx={{ color: 'action.active', mr: 1, my: 'auto' }} />
                <TextField
                    required
                    multiline
                    label='Describe the Issue'
                    fullWidth
                    variant='outlined'
                    value={formValues.issue}
                    error={errors.issue.length > 0 ? true : false}
                    helperText={errors.issue}
                    onChange={(e) => setFormValues({...formValues, issue: e.target.value})}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 3 }}>
                <PhotoCameraIcon sx={{ color: 'action.active', mr: 1, my: 'auto' }} />
                <TextField
                    type='file'
                    fullWidth
                    inputRef={ref}
                    inputProps={{multiple: true}}
                    variant='outlined'
                    onChange={handleImageChange}
                />
            </Box>

            <ImageList sx={{ width: '100%' }} cols={4} rowHeight={164}>
                {imageArr.map((imageSrc, index) => (
                    <ImageListItem key={imageSrc}>
                        <img
                            src={imageSrc}
                            srcSet={imageSrc}
                            //loading="lazy"
                        />
                        <ImageListItemBar
                            sx={{
                                background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            position="top"
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'white' }}
                                    onClick={() => removeImage(index)}
                                    >
                                    <Clear />
                                </IconButton>
                            }
                            actionPosition="right"
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Button
                sx={{mt: 3}}
                type='submit' 
                onClick={submit}
                fullWidth
                variant='contained'
            >
                SEND
            </Button>

            {success && 
                <Alert sx={{mt: 3}} severity="success">Order has been sent!</Alert>
            }
            {success === false && 
                <Alert sx={{mt: 3}} severity="error">Oops! There was an error. Please try again</Alert>
            }
            {error && <Alert sx={{mt: 3}} severity="error">Please make sure all fields are filled out before submitting</Alert>}
        </form>
    )
}

export default OrderForm;