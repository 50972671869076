import React, { useState } from 'react';
import { Paper, Box, Typography, TextField, Button, Alert } from '@mui/material';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import br from '../../images/br.png';
import LoginLogic from './loginLogic';
import{ init } from 'emailjs-com';
import * as emailjs from 'emailjs-com'
import ForgotPassword from './forgotPassword';
import Subcontractor from './subcontractor';
import ChangePassword from './changePassword';
import { ArrowForward } from '@mui/icons-material';

init(process.env.REACT_APP_EMAILJS_KEY);

function Login() {
    const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
    const [displaySubForm, setDisplaySubForm] = useState(false);    

    const {
        errors,
        loginValues,
        displayChangePassword,
        setLoginValues,
        loginUser,
    } = LoginLogic();

    return(
        <Box sx={{width: '100%', minHeight: '100vh', bgcolor: '#f4f4f4'}}>
            <Box
            sx={{width: '90%', maxWidth: '375px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <img src={br} style={{margin: '0 auto', display: 'block', width: '100px'}} />
                <Paper
                    sx={{mt: 2}}
                >
                    <Box sx={{width: '100%', py: 2, textAlign: 'center', bgcolor: '#a6192e', color: 'white'}}>
                        
                        <Typography variant='h4' sx={{lineHeight: '50px'}} > BR Dispatch</Typography>
                    </Box>

                    {!displaySubForm && !displayForgotPassword && !displayChangePassword &&
                        <Box
                          p={4}
                      >
                          <TextField
                              error={errors.email.length > 0 ? true : false}
                              fullWidth
                              variant='standard'
                              label='Email'
                              sx={{mb: 2}}
                              value={loginValues.email}
                              onChange={(e) => setLoginValues({...loginValues, email: e.target.value})}
                              helperText={errors.email}
                          />
                  
                          <TextField
                              error={errors.password.length > 0 ? true : false}
                              fullWidth
                              variant='standard'
                              label='Password'
                              type='password'
                              sx={{mb: 2}}
                              value={loginValues.password}
                              onChange={(e) => setLoginValues({...loginValues, password: e.target.value})} 
                              helperText={errors.password}
                              onKeyDown={(event) => {
                                  if(event.code === 'Enter') {
                                      loginUser()
                                  }
                              }}
                          />
                  
                          <Box textAlign='center'>
                              <Button
                                  variant='contained'
                                  sx={{width: '100%', mt: 3, bgcolor: '#fab544',  '&:hover': {
                                      backgroundColor: '#a6192e !important',
                                  },}}
                                  onClick={loginUser}
                                  
                              >
                                  Login
                              </Button>
                          </Box>
                  
                          <Box textAlign='center'>
                              <Button
                                  variant='contained'
                                  sx={{width: '100%', mt: 2, bgcolor: '#a6192e',  '&:hover': {
                                      backgroundColor: '#fab544 !important',
                                  },}}
                                  onClick={() => setDisplaySubForm(true)}
                                  endIcon={<ArrowForward />}
                              >
                                  I'm a Subcontractor
                              </Button>
                          </Box>
                          <Box textAlign='center'>
                              <Button 
                                  onClick={() => setDisplayForgotPassword(true)}
                                  sx={{fontSize: '12px', mt:2}}
                              >
                                  Forgot Password?
                              </Button>
                          </Box>
                        </Box>
                    }

                    {displayChangePassword &&
                        <ChangePassword email={loginValues.email} />
                    }

                    {displaySubForm &&
                        <Subcontractor setDisplaySubForm={setDisplaySubForm} />
                    }

                    {displayForgotPassword &&
                        <ForgotPassword setDisplayForgotPassword={setDisplayForgotPassword} />
                    }
                </Paper>
            </Box>
        </Box>
    )
}

export default Login;