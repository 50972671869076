import React from "react";
import { Typography, Divider, ImageList, ImageListItem, Button } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

function Completed(props) {

    const { order } = props;

    function upload() {

    }

    return(
        <>
            <Typography
                variant='h6'
                mb={2}
            >
                Subcontractor Updates
            </Typography>
            <Divider sx={{mb: 2}} />
            <Typography>
                Submitted: {new Date(order.subUpdates.date).toLocaleString()}
            </Typography>
            <Typography>
                Notes: {order.subUpdates.notes}
            </Typography>
            <Typography mb={2}>Manager: {order.subUpdates.manager || ''}</Typography>

            <Typography>
                <CheckCircle sx={{color: 'green'}} /> Subcontractor verified that work is complete
            </Typography>
            <Typography>
                <CheckCircle sx={{color: 'green'}} /> Manager verified that work is complete
            </Typography>
                
            <Typography my={2} variant='body2'>Manager Signature:</Typography>
            <img style={{width: '100%', maxWidth: '200px'}} src={order.subUpdates.managerSig} alt='Manager Signature' />
            {order.subUpdates.pictures.length > 0 &&
                <>
                    <ImageList sx={{ width: '100%' }} cols={4} rowHeight={164}>
                        {order.subUpdates.pictures.map((imageSrc, index) => (
                            <ImageListItem key={imageSrc}>
                                <img
                                    src={imageSrc}
                                    srcSet={imageSrc}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </>
            }
        </>
    )
}

export default Completed;