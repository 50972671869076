import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import{ init } from 'emailjs-com';
import * as emailjs from 'emailjs-com'
init(process.env.REACT_APP_EMAILJS_KEY);

function LoginLogic() {
    const history = useHistory();
    const defaultValues = {
        email: '',
        password: '',
        password2: ''
    }
    const [loginValues, setLoginValues] = useState(defaultValues);
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        password2: ''
    })

    const [displaySubForm, setDisplaySubForm] = useState(false);
    const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
    const [displayChangePassword, setDisplayChangePassword] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    function loginUser() {
        axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/loginuser?databaseId=tbdispatch', loginValues)
            .then(res => {
                if(typeof res.data === 'object') {
                    localStorage.setItem('user', JSON.stringify(res.data));
                    
                    // redirect user to change password if it's their first login
                    if(res.data.firstLogin === true) {
                        setDisplayChangePassword(true);
                    }

                    else {
                        history.push('/orders');
                    }
                }

                if(res.data === 'Email Not found') {
                    setErrors({...errors, email: 'Email Not Found'})
                }

                if(res.data === 'Incorrect Password') {
                    setErrors({...errors, password: 'Incorrect Password'})
                }
            })
    }

    function loginSub() {
        // check database for subcontractor email
        axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/loginsub?databaseId=tbdispatch', loginValues)
            .then(res => {
                console.log(res)
                if(typeof res.data === 'object') {

                    // save subcontractor email
                    localStorage.setItem('sub', res.data.email);

                    // redirect sub to subportal
                    history.push('/subportal')
                }

                if(res.data === 'Email Not found') {
                    setErrors({...errors, email: 'Email Not Found'})
                }
            })
    }

    
    function resetPassword() {
        // reset password in database
        axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatepassword?databaseId=tbdispatch', {
            email: loginValues.email,
            password: 'BRTB2022',
            firstLogin: true
        }).then(res => {
            console.log(res)
            if(res.data === 'No Email found') {
                setErrors({...errors, email: 'Email Not Found'})
            }

            else {
                let templateParams = {
                    to_name: res.data.name,
                    to_email: loginValues.email
                }
        
                emailjs.send(
                'service_gekurtf',
                'template_wzybm0g', //CONTACT TEMPLATE
                    templateParams,
                    process.env.REACT_APP_EMAILJS_KEY
                )
                setEmailSent(true);
            }
        })
    }

    function updatePassword(email) {
        // check if passwords match
        if(loginValues.password !== loginValues.password2) {
            setErrors({...errors, password2: 'Passwords do not match'})
        }

        else {
            // reset password and firstLogin to false so user will not have to reset password again
            axios.post('https://my-tb-cors.herokuapp.com/https://dispatch-fns.azurewebsites.net/api/updatepassword?databaseId=tbdispatch', {
                email: email,
                password: loginValues.password,
                firstLogin: false
            }).then(res => {
                console.log(res)
                // redirect user to orders if password is successfully updated
                if(res.data === 'No Email found') {
                    alert('Oops! There was an error. Please try again.')
                }

                else {
                    history.push('/orders');
                }
            })
        }
    }

    function backToLogin() {
        setDisplayForgotPassword(false);
        setDisplaySubForm(false)
        setDisplayChangePassword(false);
    }

    return {
        emailSent,
        errors,
        loginValues,
        displayChangePassword,
        displaySubForm,
        displayForgotPassword,
        setLoginValues,
        setDisplayForgotPassword,
        loginUser,
        loginSub,
        resetPassword,
        setDisplaySubForm,
        updatePassword,
        backToLogin
    }
}

export default LoginLogic;